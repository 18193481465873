@import '../../../assets/scss/import';

.PurchaseOrder {
	.vendorCard {
		height: 100%;
		background-color: #2e2e2e;
		.vendorText {
			color: white;
		}
	}
}
