@import '../../../assets//scss/import';

.OrderLines {
.uniquePrice {
  background-color: lighten($color-primary, 60);
}
}

.billingConflict {
  background-color: #fccccc;
}