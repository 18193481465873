@import '../../../assets/scss/import';

.AdminDashboardSummary {
	@mixin title {
		color: white;
		font-size: 2rem;
	}
	@mixin card {
		cursor: pointer;
		height: 200px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover {
			background-color: #242424;
		}
	}
	.orderCard {
		background-color: $color-primary;
		@include card;
		.title {
			@include title;
		}
	}
	.stockCard {
		background-color: $color-secondary;
		@include card;
		.title {
			@include title;
		}
	}
	.loadCard {
		background-color: $color-secondary;
		@include card;
		.title {
			@include title;
		}
	}
	.customerCard {
		background-color: $color-secondary;
		@include card;
		.title {
			@include title;
		}
	}
}
