@import '../../assets/scss/import';

@include page;

.Warehouses {

}

.transferIn {
  color: green !important;
}
.transferout {
  color: red;
}