@import '../../assets/scss/import';

.Sider {
	overflow: auto;
	height: 100vh;
	position: fixed;
	left: 0;
}

.logoContainer {
	display: flex;
	height: 32px;
	margin: 16px 16px 16px 24px;
}

.logoImgFull {
	height: 25px;
	margin-right: 2px;
}

.logoImgCollapsed {
	height: 25px;
	margin-right: 2px;
	margin-left: 10px
}

.logoText {
	height: 32px;
}

.menu {
	padding-top: 0px;
	li {
		margin-bottom: 20px !important;
	}
}

