
// Do not edit directly
// Generated on Mon, 28 Dec 2020 13:56:15 GMT

$color-brand-green: #00a838;
$color-brand-orange: #f05a23;
$color-brand-gray-1: #fafafa;
$color-brand-gray-2: #f5f5f5;
$color-brand-gray-3: #f0f0f0;
$color-brand-gray-4: #d9d9d9;
$color-brand-gray-5: #bfbfbf;
$color-brand-gray-6: #8c8c8c;
$color-brand-gray-7: #595959;
$color-brand-gray-8: #434343;
$color-brand-gray-9: #262626;
$color-brand-gray-10: #1f1f1f;
$color-brand-gray-11: #141414;
$color-brand-white: #ffffff;
$color-brand-black: #000000;