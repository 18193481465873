@import '../../../assets/scss/import';

.Order {
	.customerCard {
		height: 100%;
		background-color: #2e2e2e;
		.customerText {
			color: white;
		}
	}
	.signatureButton {
		margin-top: 100px;
	}
	.signature{
			max-width: 100%;
			margin-top: 60px;
	}
}

.signatureCanvas {
	background-color: white;
	width: 100%;
	border-radius: 10px;
	border: 1px solid black;
}
