/*
----- Typography
*/

// Alignment

.left {
	text-align: left;
}

.center {
	text-align: center;
}

.right {
	text-align: right !important;
}

/*
----- Layout
*/

// Sizing

.vh-100 {
	min-height: 100vh;
}

// Margins

@for $i from 0 through 100 {
  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }
  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }
}

// Padding

@for $i from 0 through 100 {
  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }
  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }
}

