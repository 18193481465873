@import '../../assets/scss/import';

.Header {
	background-color: #fff;
}

.left {
	text-align: left;
}

.right {
	display: flex;
	justify-content: flex-end;
}

.iconGroup {
	margin-right: 24px;
	.icon {
		cursor: pointer;
		color: $color-brand-gray-7;
		font-size: 1.1rem;
	}
	*:hover {
		color: $color-hover;
	}
}

.userDetails {
	* {
		cursor: pointer;
		&:hover {
			color: $color-hover;
			opacity: 0.95;
		}
	}
}

.drawerItem {
	cursor: pointer;
	&:hover {
		color: $color-hover;
		opacity: 0.95;
	}
}
