@import '../../assets/scss/import';

.Auth {
	height: 100vh;
	background-color: white;
	.left {
		height: 100vh;
		background: url('../../assets/img/bg_login.jpg');
		background-size: cover;
		background-position: right top;
	}
	.right {
		.loginContainer {
      width: 60%;
      margin-top: 60px;
      padding-top: 50px;
    }
    .submitButton {
      margin-top: 100px;
    }
	}
}
