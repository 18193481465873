.OrderLoader {
	position: fixed;
  top: 0;
  left: 0;
	background-color: white;
	height: 100vh;
	width: 100%;
  z-index: 9999999;
  opacity: 0.7;
  visibility: visible;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s linear;
}
